import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FormattedMessage, FormattedDate } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"

import CONSTANTS from "../constants"
import Header from "../components/Header"
import SEO from "../components/SEO"
import WeatherIcon from "../components/WeatherIcon"

import scroll from "../helpers/scroll"
import { partner } from "../helpers/api"
import { pad } from "../helpers/date"

import "./calendar.css"

import setMonth from "../actions/setMonth"

class Calendar extends Component {
  static propTypes = {
    // Redux
    loading: PropTypes.bool.isRequired,
    calendar: PropTypes.object,
    month: PropTypes.number.isRequired,
    setMonth: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.mobile = typeof window === "undefined" || window.innerWidth < 800
    this.now = new Date()
    this.year = this.now.getFullYear()
    this.startMonth = Math.max(CONSTANTS.minMonth, this.now.getMonth())
    this.limit = new Date()
    this.limit.setHours(CONSTANTS.maxBookingTime)
    this.limit.setMinutes(0)
    this.limit.setSeconds(0)
    this.state = {
      allowTomorrow: Date.now() < this.limit.getTime(),
      weathermap: {},
    }
  }

  componentDidMount() {
    if (this.state.allowTomorrow) {
      this._timeout = setTimeout(this.setTooLate, this.limit.getTime() - Date.now())
    }
    const { loading, month, lang } = this.props
    if (!loading && this.mobile && month !== this.startMonth) {
      scroll("#m" + month, true)
    }

    this.fetchWeather(lang)
  }

  componentDidUpdate(prevProps) {
    const { loading, month, lang } = this.props
    if (!loading && this.mobile && prevProps.month !== month) {
      scroll(month === this.startMonth ? ".lists" : "#m" + month)
    }
    if (lang !== prevProps.lang) {
      this.fetchWeather(lang)
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timeout)
  }

  fetchWeather = (lang) => {
    const id = process.env.GATSBY_OPENWEATHER_CITY
    const key = process.env.GATSBY_OPENWEATHER_KEY
    fetch(`https://api.openweathermap.org/data/2.5/forecast?mode=json&lang=${lang}&id=${id}&appid=${key}`)
      .then((response) => response.json())
      .then(({ list }) => {
        const weathermap = {}
        list.forEach((data) => {
          const datetime = data.dt_txt.split(" ")
          const ymd = datetime[0]
          const time = datetime[1].split(":")
          const hour = Number(time[0])
          if (!weathermap[ymd]) {
            weathermap[ymd] = {}
          }
          if (hour > 8 && hour < 12) {
            weathermap[ymd].morning = data.weather[0]
          } else if (hour > 12 && hour < 16) {
            weathermap[ymd].afternoon = data.weather[0]
          }
        })
        this.setState({
          weathermap,
        })
      })
  }

  setTooLate = () => {
    this.setState({
      allowTomorrow: false,
    })
  }

  handlePrevious = ({ currentTarget }) => {
    this.props.setMonth(this.mobile ? currentTarget.getAttribute("data-month") : this.props.month - 1)
  }

  handleNext = ({ currentTarget }) => {
    this.props.setMonth(this.mobile ? currentTarget.getAttribute("data-month") : this.props.month + 1)
  }

  getContent = (data, key, date) => {
    const slot = data && (data[key] || data[key + "_full"])
    const weather = (this.state.weathermap[date] || {})[key]

    let message
    let values
    let cls = "halfday "
    if (slot) {
      if (slot.closed || (slot.partner && slot.partner !== partner)) {
        message = "closed"
        cls += "full"
      } else if (slot.booked >= CONSTANTS.maxPeople) {
        message = "full"
        cls += "full"
      } else {
        message = "spots"
        values = { num: Math.max(0, CONSTANTS.maxPeople - (slot.booked || 0)) }
      }
    } else {
      message = "spots"
      values = { num: CONSTANTS.maxPeople }
    }
    if (data && data.day && (data.day.closed || (data.day.partner && data.day.partner !== partner))) {
      message = "closed"
      cls += "full"
    }
    return (
      <div className={cls}>
        {weather && (
          <span title={weather.description} className="weather">
            <WeatherIcon code={weather.icon} />
          </span>
        )}
        <FormattedMessage id={message} values={values} />
      </div>
    )
  }

  // for desktop:
  renderCalendar = () => {
    const { month, calendar } = this.props
    const { allowTomorrow } = this.state
    const monthStr = pad(month + 1)

    const book = calendar[monthStr] || {}

    const days = new Date(this.year, month + 1, 0).getDate() // day 0 = previous day
    const current = new Date(this.year, month, 1)
    const firstDay = current.getDay() - 1

    const weekdays = []
    for (let i = 1; i <= 7; i++) {
      weekdays.push(<FormattedMessage key={"w" + i} id={"weekday" + i} />)
    }

    const matrix = [[]]
    let line = 0
    for (let i = 0; i < firstDay; i++) {
      matrix[line][i] = <div className="day off" key={"before" + i} />
    }
    let cell = firstDay
    for (let day = 1; day <= days; day++) {
      if (cell > 6) {
        cell = 0
        line++
        matrix[line] = []
      }
      let content
      let className = "day past"
      const offset = allowTomorrow ? 0 : 1
      //if not in the past, nor today, nor tomorrow if today is late:
      if (month > this.now.getMonth() || day > this.now.getDate() + offset) {
        className = "day"
        const dayStr = pad(day)
        const data = book[dayStr]
        const to = "/book/"
        const date = new Date(this.year, month, day)
        const ymd = `${this.year}-${monthStr}-${dayStr}`

        content = (
          <Link to={to} state={{ date, monthStr, dayStr }}>
            {this.getContent(data, "morning", ymd)}
            {this.getContent(data, "afternoon", ymd)}
          </Link>
        )
      }

      matrix[line][cell] = (
        <div className={className} key={"day" + day}>
          <div className="num">{day}</div>
          {content}
        </div>
      )
      cell++
    }
    for (let i = cell; i < 7; i++) {
      matrix[line][i] = <div className="day off" key={"after" + i} />
    }

    return (
      <main>
        <h2 className="month">
          <button onClick={this.handlePrevious}>
            <MdKeyboardArrowLeft color="#7D4900" size={30} />
          </button>
          <FormattedDate value={current} month="long" />
          <button onClick={this.handleNext}>
            <MdKeyboardArrowRight color="#7D4900" size={30} />
          </button>
        </h2>
        <div className="weekdays">{weekdays}</div>
        <div className="calendar">
          {matrix.map((row, index) => (
            <div className="week" key={"week" + index}>
              {row}
            </div>
          ))}
        </div>
      </main>
    )
  }

  // for mobile:
  renderList = () => {
    const blocks = []

    const startMonth = Math.max(CONSTANTS.minMonth, this.now.getMonth())
    for (let month = startMonth; month <= CONSTANTS.maxMonth; month++) {
      const monthStr = pad(month + 1)
      const book = this.props.calendar[monthStr] || {}
      const days = new Date(this.year, month + 1, 0).getDate()

      let startDay = 1
      if (month === this.now.getMonth()) {
        const offset = this.state.allowTomorrow ? 1 : 2
        startDay = this.now.getDate() + offset
      }

      const lines = []
      for (let day = startDay; day <= days; day++) {
        const dayStr = pad(day)
        const to = "/book/"
        const data = book[dayStr]
        const date = new Date(this.year, month, day)
        const ymd = `${this.year}-${monthStr}-${dayStr}`

        lines.push(
          <Link key={ymd} to={to} state={{ date, monthStr, dayStr }} className="line">
            <div className="num">{day}</div>
            {this.getContent(data, "morning", ymd)}
            <div key="separator" className="separator" />
            {this.getContent(data, "afternoon", ymd)}
          </Link>
        )
      }

      blocks.push(
        <div key={month} id={"m" + month}>
          <h2 className="month">
            <button onClick={this.handlePrevious} data-month={month - 1}>
              <MdKeyboardArrowLeft color="#7D4900" size={30} />
            </button>
            <FormattedDate value={new Date(this.year, month, 1)} month="long" />
            <button onClick={this.handleNext} data-month={month + 1}>
              <MdKeyboardArrowRight color="#7D4900" size={30} />
            </button>
          </h2>
          <div className="list">
            <div className="dayhead">
              <FormattedMessage id="morning" />
              <FormattedMessage id="afternoon" />
            </div>
            {lines}
          </div>
        </div>
      )
    }

    return <div className="lists">{blocks}</div>
  }

  render() {
    let calendar
    if (this.props.loading) {
      calendar = (
        <div id="loader">
          <div id="arc" />
        </div>
      )
    } else if (this.now.getMonth() > CONSTANTS.maxMonth) {
      calendar = (
        <main className="end">
          <FormattedMessage id="after_season" />
        </main>
      )
    } else {
      calendar = this.mobile ? this.renderList() : this.renderCalendar()
    }

    return (
      <div>
        <SEO />
        <Header>
          <FormattedMessage id="calendar_title" tagName="h1" />
        </Header>
        {calendar}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.app.loading,
  calendar: state.app.calendar,
  month: state.app.month,
  lang: state.app.lang,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setMonth,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
