import React from "react"

import {
  IoIosSunny,
  IoIosPartlySunny,
  IoIosCloudy,
  IoMdCloudy,
  IoIosRainy,
  IoMdRainy,
  IoIosThunderstorm,
  IoIosSnow,
  IoMdMenu,
} from "react-icons/io"

const iconMap = {
  "01d": IoIosSunny,
  "02d": IoIosPartlySunny,
  "03d": IoIosCloudy,
  "04d": IoMdCloudy,
  "09d": IoIosRainy,
  "10d": IoMdRainy,
  "11d": IoIosThunderstorm,
  "13d": IoIosSnow,
  "50d": IoMdMenu,
}

const WeatherIcon = ({ code, ...props }) => {
  if (typeof code !== "string") {
    return null
  }
  const Icon = iconMap[code.replace("n", "d")]
  if (!Icon) {
    return null
  }
  return <Icon size={24} {...props} />
}

export default WeatherIcon
